import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  filter(e) {
    const selectedFilter = e.target.value
    const selectedOption = e.target.options[e.target.selectedIndex]
    const filterParams = selectedOption.getAttribute("data-filter-params")

    let url = `${window.location.pathname}?filter=${selectedFilter}`
    if (filterParams) { url += `&filter_params=${filterParams}` }

    Turbo.visit(url)
  }
}
