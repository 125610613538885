import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = { frameId: String, queryName: String, url: String }

  get frame() {
    return document.getElementById(this.frameIdValue)
  }

  refresh(e) {
    const selectedOption = e.target.options[e.target.selectedIndex]

    this.frame.src = `${this.urlValue}?${this.queryNameValue}=${selectedOption.value}`
  }
}
