import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = { path: String }

  navigate(e) {
    const id = e.target.value

    const url = this.pathValue.replace(":id", id)

    Turbo.visit(url)
  }
}
